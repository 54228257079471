export const api_url = "https://assg-3-am.azurewebsites.net/api"

const api_key = "WKss3EtKTKc6wPeRC4MN7u8h1cI0YRwgB_8B_FX-appRAzFuLYRoSQ=="

export const api_config = {
    headers:{
      'x-functions-key': api_key,
    }
  };

